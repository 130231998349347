import { Text, Separator, PrimaryButton, TextField } from '@fluentui/react';
import { Grid } from '@material-ui/core';
import * as React from 'react';

interface IDecimalInput {
    title: string;
    label: string;
    defaultValue?: string;
    onNext: (value: string) => void;
}

export const DecimalInput: React.FC<IDecimalInput> = ({ title, label, defaultValue, onNext }) => {
    const [inputValue, setInputValue] = React.useState(defaultValue ? defaultValue : '');
    const onChangeInputTextFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setInputValue(newValue || '');
        },
        [],
    );

    const onNextClick = () => {
        const input = inputValue;
        setInputValue('');
        onNext(input);
    }

    return <React.Fragment>
        <Grid item sm={12} style={{ padding: '.5rem', width: '100%' }}>
            <Separator>Set {title} information</Separator>
        </Grid>
        <Grid item xs={12} style={{ padding: '.5rem' }}>
            <Text variant='large' style={{ marginBottom: '1rem' }}>{label}:</Text>
        </Grid>
        <Grid item xs={12} style={{ padding: '.5rem' }}>
            <TextField
                value={inputValue}
                onChange={onChangeInputTextFieldValue}
                inputMode={'decimal'}
            />
        </Grid>
        <Grid item xs={12} style={{ padding: '.5rem' }}>
            <PrimaryButton onClick={() => onNextClick()}>Next</PrimaryButton>
        </Grid>
    </React.Fragment>
}
