import { getPostOptions } from "../Auth/Auth.util";
import { SERVER_URL } from "../Utils/configuration";
import { coordsInfo } from "../Utils/coordsinfo";
import { ReadingInfo } from "../Utils/readinginfo";

export const getLocationInfo = async (coordinates: string): Promise<coordsInfo | undefined> => {
    try{
        const atlasUrl = `https://atlas.microsoft.com/search/address/reverse/json?subscription-key=dCZ4ge-6m-lGBzwpy1LcY1laddlSvtrkw2CWORXbPQY&api-version=1.0&query=${coordinates}`;
        const response = await fetch(atlasUrl);

        if(response.ok){
            const coordsInfo: coordsInfo = await response.json();
            return coordsInfo;
        }

        console.log('response not ok');
        console.dir(response);
    }catch(err){
        console.warn('Unable to fetch coordinates information');
        console.error(err);
    }
    
    return undefined;
}

export const addFuelIntake = async (vehicle: string,
    fuelIntake: string,
    fuelType: string,
    company: string,
    odoReading: string,
    literPrice: string,
    totalPrice: string,
    coordinates: string): Promise<void> => {

    const now = new Date();
    const cost = {
        odo: odoReading,
        price: totalPrice,
        comment: '',
        costType: 'fuel',
        date: now.toISOString(),
        licenseplate: vehicle,
        petrolType: fuelType,
        liters: fuelIntake,
        literPrice: literPrice,
        location: {
            name: '',
            coordinates: coordinates
        },
        company: company
    }

    const payload = JSON.stringify(cost);
    localStorage.setItem(`FuelUp_${odoReading}`, payload);

    const readingInfo: ReadingInfo = { odo: cost.odo, status: 'pending' };
    storeReadingInfo(readingInfo);

    return await saveFuelIntake(payload, readingInfo);
}

export const retryFuelIntakeSave = async (odo: string): Promise<void> => {
    const payload = localStorage.getItem(`FuelUp_${odo}`);

    if (payload === null) {
        throw Error("Unable to find fuel intake information");
    }

    const readingInfo: ReadingInfo = { odo: odo, status: 'pending' };
    storeReadingInfo(readingInfo);

    return await saveFuelIntake(payload, readingInfo);
}

const saveFuelIntake = async (payload: string, readingInfo: ReadingInfo): Promise<void> => {
    const options = await getPostOptions(payload);
    const responseObject = await fetch(`${SERVER_URL}/api/AddVehicleCost`, options);

    if (!responseObject.ok) {
        readingInfo.status = 'error';
        storeReadingInfo(readingInfo);
        throw Error("Unable to save cost: " + responseObject.statusText);
    }

    readingInfo.status = 'succes';
    storeReadingInfo(readingInfo);
}

const storeReadingInfo = (readingInfo: ReadingInfo): void => {
    const storedItems = localStorage.getItem('FuelUp_Readings');
    let readings: ReadingInfo[];

    if (storedItems !== null) {
        readings = JSON.parse(storedItems);
    } else {
        readings = [];
    }

    const existingReadingIndex = readings.findIndex(r => r.odo === readingInfo.odo);

    if (existingReadingIndex === -1) {
        readings.unshift(readingInfo);
    } else {
        readings[existingReadingIndex] = readingInfo;
    }

    localStorage.setItem('FuelUp_Readings', JSON.stringify(readings));
}
