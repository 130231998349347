import { FontIcon, mergeStyles, Text, Separator, Depths } from '@fluentui/react';
import { Grid } from '@material-ui/core';
import * as React from 'react';

interface IVehiclePicker {
    onSelectVehicle: (licensePlate: string) => void;
    onShowHistory: () => void;
}

const iconClass = mergeStyles({
    fontSize: 50,
    height: 50,
    width: 50,
    margin: '1rem 1rem .5rem 1rem',
});

export const VehiclePicker: React.FC<IVehiclePicker> = ({ onSelectVehicle, onShowHistory }) => {
    return <React.Fragment>
        <Grid item sm={12} style={{ padding: '.5rem', width: '100%' }}>
            <Separator>Choose vehicle</Separator>
        </Grid>
        <Grid item xs={12} style={{ padding: '.5rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'column', boxShadow: Depths.depth8 }} onClick={() => onSelectVehicle('p-102-ns')}>
                <FontIcon iconName="ParkingLocationMirrored" className={iconClass} />
                <Text variant='large' style={{ marginBottom: '1rem' }}>P-102-NS</Text>
            </div>
        </Grid>
        <Grid item xs={6} style={{ padding: '.5rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'column', boxShadow: Depths.depth8 }} onClick={() => onSelectVehicle('mv-nx-94')}>
                <FontIcon iconName="Cycling" className={iconClass} />
                <Text variant='large' style={{ marginBottom: '1rem' }}>MV-NX-94</Text>
            </div>
        </Grid>
        <Grid item xs={6} style={{ padding: '.5rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'column', boxShadow: Depths.depth8 }} onClick={() => onSelectVehicle('mr-rs-86')}>
                <FontIcon iconName="Cycling" className={iconClass} />
                <Text variant='large' style={{ marginBottom: '1rem' }}>MR-RS-86</Text>
            </div>
        </Grid>
        <Grid item xs={12} style={{ padding: '.5rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'column', boxShadow: Depths.depth8 }} onClick={() => onSelectVehicle('k-877-gd')}>
                <FontIcon iconName="ParkingLocationMirrored" className={iconClass} />
                <Text variant='large' style={{ marginBottom: '1rem' }}>K-877-GD</Text>
            </div>
        </Grid>
        <Grid item sm={12} style={{ padding: '.5rem', width: '100%' }}>
            <Separator>Options</Separator>
        </Grid>
        <Grid item xs={12} style={{ padding: '.5rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'column', boxShadow: Depths.depth8 }} onClick={() => onShowHistory()}>
                <FontIcon iconName="History" className={iconClass} />
                <Text variant='large' style={{ marginBottom: '1rem' }}>history</Text>
            </div>
        </Grid>
    </React.Fragment>
}
