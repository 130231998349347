export const SERVER_URL="https://skf-ft-fa.azurewebsites.net";
export const API_SCOPE="api://176123ee-682d-4c39-ad6c-84b817501dca/user_impersonation";
export const CLIENT_ID="3c7f54bc-c7b4-402f-9984-99d03f7f1fb1";
export const AUTHORITY="https://login.microsoftonline.com/6b2b26e7-d022-4011-af0d-2d6dbdede3ed";
export const REDIRECT_URI="https://fuelup.vanderknijff.eu";

/*DEV*/
// export const SERVER_URL="https://skf-test-fa.azurewebsites.net";
// export const API_SCOPE="api://2a55e068-6af6-4a03-9ed2-10c6993548bd/user_impersonation";
// export const CLIENT_ID="45f7a401-3a84-4c1f-b5ad-0ae10ff66e4b";
// export const AUTHORITY="https://login.microsoftonline.com/f183a374-98c8-4e15-b9e5-949af56ec3d2";
// export const REDIRECT_URI="http://localhost:3000";