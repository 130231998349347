import { Text, Separator, PrimaryButton, ProgressIndicator } from '@fluentui/react';
import { Grid } from '@material-ui/core';
import * as React from 'react';

interface IOverview {
    vehicle: string;
    fuelType: string;
    company: string;
    odoReading: string;
    fuelIntake: string;
    literPrice: string;
    totalPrice: string;
    onSubmit: () => void;
}

export const Overview: React.FC<IOverview> = ({ vehicle, fuelIntake, fuelType, company, odoReading, literPrice, totalPrice, onSubmit }) => {
    const [submitting, setIsSubmitting] = React.useState<boolean>(false);

    const submit = React.useCallback(() => {
        onSubmit();
    }, [onSubmit]);

    const overviewCallback = React.useCallback(() => {
        const submitFuelup = () => {
            setIsSubmitting(true);
            submit();
        }

        return submitting === true ?
            <React.Fragment>
                <Grid item xs={12}>
                    <ProgressIndicator label="Saving" description="Saving cost" />
                </Grid>
            </React.Fragment> :
            <React.Fragment>
                <Grid item xs={3} style={{ padding: '.5rem' }}>
                    <Text>Vehicle:</Text>
                </Grid>
                <Grid item xs={9} style={{ padding: '.5rem' }}>
                    <Text>{vehicle}</Text>
                </Grid>
                <Grid item xs={3} style={{ padding: '.5rem' }}>
                    <Text>Company:</Text>
                </Grid>
                <Grid item xs={9} style={{ padding: '.5rem' }}>
                    <Text>{company}</Text>
                </Grid>
                <Grid item xs={3} style={{ padding: '.5rem' }}>
                    <Text>Fuel type:</Text>
                </Grid>
                <Grid item xs={9} style={{ padding: '.5rem' }}>
                    <Text>{fuelType}</Text>
                </Grid>
                <Grid item xs={3} style={{ padding: '.5rem' }}>
                    <Text>ODO:</Text>
                </Grid>
                <Grid item xs={9} style={{ padding: '.5rem' }}>
                    <Text>{odoReading} km</Text>
                </Grid>
                <Grid item xs={3} style={{ padding: '.5rem' }}>
                    <Text>Fuel intake:</Text>
                </Grid>
                <Grid item xs={9} style={{ padding: '.5rem' }}>
                    <Text>{fuelIntake} Liter</Text>
                </Grid>
                <Grid item xs={3} style={{ padding: '.5rem' }}>
                    <Text>Liter price:</Text>
                </Grid>
                <Grid item xs={9} style={{ padding: '.5rem' }}>
                    <Text>€ {literPrice}</Text>
                </Grid>
                <Grid item xs={3} style={{ padding: '.5rem' }}>
                    <Text>Total price:</Text>
                </Grid>
                <Grid item xs={9} style={{ padding: '.5rem' }}>
                    <Text>€ {totalPrice}</Text>
                </Grid>

                <Grid item xs={12} style={{ padding: '.5rem' }}>
                    <PrimaryButton onClick={() => submitFuelup()}>Save</PrimaryButton>
                </Grid>
            </React.Fragment>

    }, [submitting, company, fuelIntake, fuelType, literPrice, odoReading, submit, totalPrice, vehicle]);

    return <React.Fragment>
        <Grid item sm={12} style={{ padding: '.5rem', width: '100%' }}>
            <Separator>Overview</Separator>
        </Grid>
        {overviewCallback()}
    </React.Fragment>
}
