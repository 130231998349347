import React from 'react';
import './App.css';
import { Grid } from '@material-ui/core';
import { ThemeProvider, mergeStyles, FontIcon, Text } from '@fluentui/react';
import { CompanyPicker, FuelPicker, OdoInput, Overview, VehiclePicker, DecimalInput, History, LocationFinder } from './Components';
import { addFuelIntake, retryFuelIntakeSave } from './Services/Vehicle.service';

const iconClass = mergeStyles({
  fontSize: 50,
  height: 50,
  width: 50,
  margin: '1rem 1rem .5rem 1rem',
});

const getPrice = (liters: string, literPrice: string): string => {
  const nLiters = parseFloat(liters);
  const nLiterPrice = parseFloat(literPrice);

  const totalPrice = nLiters * nLiterPrice;
  return totalPrice.toFixed(2);
}

const FuelUp: React.FC = () => {
  const [currentStep, setCurrentStep] = React.useState<'vehicle' | 'fuel' | 'location' | 'company' | 'odo' | 'liter' | 'literPrice' | 'totalPrice' | 'submit' | 'error' | 'history'>('vehicle');
  const [vehicle, setVehicle] = React.useState<string>('');
  const [fuel, setFuel] = React.useState<string>('');
  const [company, setCompany] = React.useState<string>('');
  const [odo, setOdo] = React.useState<string>('');
  const [fuelIntake, setFuelIntake] = React.useState<string>('');
  const [literPrice, setLiterPrice] = React.useState<string>('');
  const [totalPrice, setTotalPrice] = React.useState<string>('');
  const [location, setLocation] = React.useState<string>('');

  const inputArea = React.useCallback(() => {
    const onSaveFuelIntake = async () => {
      try {
        await addFuelIntake(
          vehicle,
          fuelIntake,
          fuel,
          company,
          odo,
          literPrice,
          totalPrice,
          location
        );

        setCurrentStep('vehicle');
      } catch {
        setCurrentStep('error');
      }
    }

    const onSaveFuelIntakeRetry = async (odo: string) => {
      await retryFuelIntakeSave(odo);
    }

    switch (currentStep) {
      case 'vehicle':
        return <VehiclePicker onSelectVehicle={(plate: string) => { setVehicle(plate); setCurrentStep('fuel') }} onShowHistory={() => setCurrentStep('history')} />;
      case 'fuel':
        return <FuelPicker onSelectFuel={(fuelType: string) => { setFuel(fuelType); setCurrentStep('location'); }} />;
      case 'location':
        return <LocationFinder onNext={(coords: string, companyName?: string) => {
          setLocation(coords);
          if (companyName) {
            setCompany(companyName);
            setCurrentStep('odo');
          } else {
            setCurrentStep('company');
          }
        }} />;
      case 'company':
        return <CompanyPicker onSelectCompany={(companyName: string) => { setCompany(companyName); setCurrentStep('odo'); }} />;
      case 'odo':
        return <OdoInput onNext={(odoReading: string) => { setOdo(odoReading); setCurrentStep('liter'); }} />;
      case 'liter':
        return <DecimalInput key={'liters'} title='liters' label='Liters' onNext={(liters: string) => { setFuelIntake(liters); setCurrentStep('literPrice'); }} />;
      case 'literPrice':
        return <DecimalInput key={'literPrijs'} title='liter prijs' label='Liter prijs' onNext={(price: string) => { setLiterPrice(price); setCurrentStep('totalPrice'); }} />;
      case 'totalPrice':
        return <DecimalInput key={'prijs'} title='prijs' label='Prijs' defaultValue={getPrice(fuelIntake, literPrice)} onNext={(price: string) => { setTotalPrice(price); setCurrentStep('submit'); }} />;
      case 'history':
        return <History onPreviousPage={() => setCurrentStep('vehicle')} onRetrySave={onSaveFuelIntakeRetry} />
      case 'error':
        return <Text>Unable to save fuel cost.</Text>
      case 'submit':
      default:
        return <Overview
          vehicle={vehicle}
          fuelType={fuel}
          company={company}
          odoReading={odo}
          fuelIntake={fuelIntake}
          literPrice={literPrice}
          totalPrice={totalPrice}
          onSubmit={() => onSaveFuelIntake()}
        />
    }
  }, [currentStep, fuel, vehicle, company, odo, fuelIntake, literPrice, totalPrice, location]);

  return (
    <ThemeProvider>
      <Grid container>
        <Grid item sm={12} style={{ padding: '.5rem', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexFlow: 'row' }}>
            <FontIcon iconName="Financial" className={iconClass} />
            <Text variant={'xLarge'}>Fuel up</Text>
          </div>
        </Grid>
        {inputArea()}
      </Grid>
    </ThemeProvider>
  );
}

export default FuelUp;
