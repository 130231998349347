import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import FuelUp from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from './Auth/Auth.config';
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { initializeIcons } from '@fluentui/react';

export const msalInstance = new PublicClientApplication(msalConfig);

initializeIcons();

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
        <FuelUp />
      </MsalAuthenticationTemplate>
    </MsalProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
