import { Configuration, RedirectRequest } from "@azure/msal-browser";
import { API_SCOPE, AUTHORITY, CLIENT_ID, REDIRECT_URI, SERVER_URL } from "../Utils/configuration";

export const msalConfig: Configuration = {
    auth: {
        clientId: CLIENT_ID,
        authority: AUTHORITY,
        redirectUri: REDIRECT_URI,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: ["User.Read"]
};

export const faRequest = {
    url: SERVER_URL,
    scopes: [API_SCOPE]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};