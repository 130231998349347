import { FontIcon, mergeStyles, Text, Separator, Depths } from '@fluentui/react';
import { Grid } from '@material-ui/core';
import * as React from 'react';

interface IFuelPicker {
    onSelectFuel: (fuelType: string) => void;
}

const iconClass = mergeStyles({
    fontSize: 50,
    height: 50,
    width: 50,
    margin: '1rem 1rem .5rem 1rem',
});

export const FuelPicker: React.FC<IFuelPicker> = ({ onSelectFuel }) => {
    return <React.Fragment>
        <Grid item sm={12} style={{ padding: '.5rem', width: '100%' }}>
            <Separator>Choose fuel</Separator>
        </Grid>
        <Grid item xs={6} style={{ padding: '.5rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'column', boxShadow: Depths.depth8 }} onClick={() => onSelectFuel('E5')}>
                <FontIcon iconName="TestBeaker" className={iconClass} />
                <Text variant='large' style={{ marginBottom: '1rem' }}>E5</Text>
            </div>
        </Grid>
        <Grid item xs={6} style={{ padding: '.5rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'column', boxShadow: Depths.depth8 }} onClick={() => onSelectFuel('E10')}>
                <FontIcon iconName="TestBeakerSolid" className={iconClass} />
                <Text variant='large' style={{ marginBottom: '1rem' }}>E10</Text>
            </div>
        </Grid>
    </React.Fragment>
}
