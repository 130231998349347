import { Text, Separator, FontIcon, mergeStyles, Depths, SharedColors, IStyle, ProgressIndicator } from '@fluentui/react';
import { Grid } from '@material-ui/core';
import * as React from 'react';
import { ReadingInfo } from '../Utils/readinginfo';

interface IHistory {
    onPreviousPage: () => void;
    onRetrySave: (odo: string) => Promise<void>;
}

const iconStyle: IStyle = {
    fontSize: 18,
    height: 20,
    width: 20,
    marginLeft: '.2rem',
    marginRight: '.2rem'
};

const iconClass = mergeStyles(iconStyle);
const validIconClass = mergeStyles(iconStyle, {
    color: SharedColors.green20
});
const invalidIconClass = mergeStyles(iconStyle, {
    color: SharedColors.red20
});

const readingStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '2.5rem',
    flexFlow: 'row',
    boxShadow: Depths.depth8,
    marginTop: '.5rem'
}

export const History: React.FC<IHistory> = ({ onPreviousPage, onRetrySave }) => {
    const [odoReadings, setOdoReadings] = React.useState<ReadingInfo[]>([]);
    const [submitting, setIsSubmitting] = React.useState<boolean>(false);

    const loadReadings = () => {
        const storedReadings = localStorage.getItem('FuelUp_Readings');
        if (storedReadings !== null) {
            const readings: ReadingInfo[] = JSON.parse(storedReadings);
            setOdoReadings(readings);
        }
    }

    React.useEffect(() => {
        if (!submitting) {
            loadReadings();
        }
    }, [submitting]);

    const retrySave = React.useCallback(async (odo: string) => {
        await onRetrySave(odo);
    }, [onRetrySave]);

    const readingList = React.useCallback(() => {
        const save = async (reading: ReadingInfo) => {
            if (reading.status === 'error' || reading.status === 'pending') {
                setIsSubmitting(true);
                await retrySave(reading.odo);
                setOdoReadings([]);
                setIsSubmitting(false);
            }
        }

        if (submitting) {
            return <ProgressIndicator label="Saving" description="Saving cost" />
        } else if (odoReadings.length === 0) {
            return <React.Fragment><Text>No previous inputs available</Text></React.Fragment>
        } else {
            return <React.Fragment>
                {odoReadings.map((reading: ReadingInfo) => {
                    return <div style={readingStyle} onClick={() => save(reading)} key={reading.odo}>
                        <FontIcon iconName="TextDocument" className={iconClass} />
                        <Text>{reading.odo}</Text>
                        {reading.status === 'succes' && <FontIcon iconName="CompletedSolid" className={validIconClass} />}
                        {reading.status === 'error' && <FontIcon iconName="StatusErrorFull" className={invalidIconClass} />}
                        {reading.status === 'pending' && <FontIcon iconName="ProgressRingDots" className={iconClass} />}
                    </div>
                })}
            </React.Fragment>
        }
    }, [odoReadings, submitting, retrySave]);

    return <React.Fragment>
        <Grid item sm={12} style={{ padding: '.5rem', width: '100%' }}>
            <Separator>History</Separator>
        </Grid>
        <Grid item xs={12} style={{ padding: '.5rem' }} container justifyContent='center' alignContent='center' alignItems='center'>
            <FontIcon iconName="NavigateBack" className={iconClass} onClick={() => onPreviousPage()} />
            <Text style={{ marginBottom: '.3rem' }} onClick={() => onPreviousPage()} >return to vehicle page</Text>
        </Grid>
        <Grid item xs={12} style={{ padding: '.5rem' }}>
            {readingList()}
        </Grid>
    </React.Fragment>
}
