import { Text, Separator, PrimaryButton, DefaultButton, ProgressIndicator } from '@fluentui/react';
import { Grid } from '@material-ui/core';
import * as React from 'react';
import { CompanyPicker } from '.';
import { getLocationInfo } from '../Services/Vehicle.service';
import { coordsInfo } from '../Utils/coordsinfo';

interface ILocationFinder {
    onNext: (location: string, company?: string) => void;
};

const gridStyle: React.CSSProperties = {
    padding: '.5rem'
};

const geoOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
};

type coordinates = {
    latitude: number;
    longitude: number;
}

type locationmap = {
    position: string;
    name: string;
}

const getMappedLocations = (): locationmap[] => {
    const mappedCompaniesInfo = localStorage.getItem('FuelUp_LocationMap');
    if (mappedCompaniesInfo !== null && mappedCompaniesInfo !== '') {
        const mappedCompanies: locationmap[] = JSON.parse(mappedCompaniesInfo);
        return mappedCompanies;
    }

    return [];
}

const addMappedLocation = (coords: string, name: string) => {
    const mappedCompanies = getMappedLocations();
    mappedCompanies.push({ position: coords, name: name });
    localStorage.setItem('FuelUp_LocationMap', JSON.stringify(mappedCompanies));
}

export const LocationFinder: React.FC<ILocationFinder> = ({ onNext }) => {
    const [locatingInProgress, setLocatingInProgress] = React.useState<boolean>(true);
    const [infoLoadInProgress, setInfoLoadInProgress] = React.useState<boolean>(false);
    const [mappingCompany, setMappingCompany] = React.useState<boolean>(false);
    const [location, setLocation] = React.useState<coordinates>();
    const [mappedLocation, setMappedLocation] = React.useState<string>();
    const [coordsInfo, setCoordsInfo] = React.useState<coordsInfo>();

    React.useEffect(() => {
        navigator.geolocation.getCurrentPosition((pos: GeolocationPosition) => {
            setLocation({ latitude: pos.coords.latitude, longitude: pos.coords.longitude });
            setLocatingInProgress(false);
        }, (err: GeolocationPositionError) => {
            setLocatingInProgress(false);
        }, geoOptions);
    }, []);

    React.useEffect(() => {
        if (location) {
            setInfoLoadInProgress(true);
            (async () => {
                const info = await getLocationInfo(`${location.latitude},${location.longitude}`);
                if (info !== undefined) {
                    const mappedCompanies: locationmap[] = getMappedLocations();
                    const companyIndex = mappedCompanies.findIndex(c => c.position === info.addresses[0].position);
                    if (companyIndex !== -1) {
                        setMappedLocation(mappedCompanies[companyIndex].name);
                    }

                    setCoordsInfo(info);
                }
                setInfoLoadInProgress(false);
            })();
        }
    }, [location]);

    const locationInfo = React.useCallback(() => {
        return <React.Fragment>
            {locatingInProgress &&
                <Grid item xs={12} style={gridStyle}>
                    <ProgressIndicator label="Geocoordinates" description="Determine current location" />
                </Grid>
            }
            {!locatingInProgress &&
                <React.Fragment>
                    <Grid item xs={4} style={gridStyle}>
                        <Text>Latitude:</Text>
                    </Grid>
                    <Grid item xs={8} style={gridStyle}>
                        <Text>{location?.latitude}</Text>
                    </Grid>

                    <Grid item xs={4} style={gridStyle}>
                        <Text>Longitude:</Text>
                    </Grid>
                    <Grid item xs={8} style={gridStyle}>
                        <Text>{location?.longitude}</Text>
                    </Grid>
                </React.Fragment>

            }
        </React.Fragment>
    }, [locatingInProgress, location]);

    const coordinatesInfo = React.useCallback(() => {
        if (infoLoadInProgress) {
            return <Grid item xs={12} style={gridStyle}>
                <ProgressIndicator label="GeoInformation" description="Fetch location info" />
            </Grid>;
        }
        if (coordsInfo === undefined) {
            return <React.Fragment></React.Fragment>
        }

        return <React.Fragment>
            <Grid item xs={12} style={gridStyle}>
                <Separator>Location information</Separator>
            </Grid>

            {coordsInfo.addresses.map(address => {
                return <React.Fragment key={address.position}>
                    <Grid item xs={4} style={gridStyle}>
                        <Text>Address:</Text>
                    </Grid>
                    <Grid item xs={8} style={gridStyle}>
                        <Text>{address.address.streetNameAndNumber}</Text>
                    </Grid>

                    <Grid item xs={4} style={gridStyle}>
                        <Text>Postal code:</Text>
                    </Grid>
                    <Grid item xs={8} style={gridStyle}>
                        <Text>{address.address.extendedPostalCode}</Text>
                    </Grid>

                    <Grid item xs={4} style={gridStyle}>
                        <Text>Municipality:</Text>
                    </Grid>
                    <Grid item xs={8} style={gridStyle}>
                        <Text>
                            {address.address.municipality}
                            {address.address.municipalitySubdivision && <React.Fragment>, {address.address.municipalitySubdivision}</React.Fragment>}
                        </Text>
                    </Grid>

                    <Grid item xs={4} style={gridStyle}>
                        <Text>Position:</Text>
                    </Grid>
                    <Grid item xs={8} style={gridStyle}>
                        <Text>{address.position}</Text>
                    </Grid>

                    {mappedLocation === undefined &&
                        <Grid item xs={12} style={gridStyle} container justifyContent='center'>
                            <DefaultButton onClick={() => setMappingCompany(true)}>Map company</DefaultButton>
                        </Grid>
                    }
                    {mappedLocation !== undefined &&
                        <React.Fragment>
                            <Grid item xs={4} style={gridStyle}>
                                <Text>Company:</Text>
                            </Grid>
                            <Grid item xs={8} style={gridStyle}>
                                <Text>{mappedLocation}</Text>
                            </Grid>
                        </React.Fragment>
                    }
                </React.Fragment>;
            })}
        </React.Fragment>
    }, [coordsInfo, infoLoadInProgress, mappedLocation])

    const viewSelector = React.useCallback(() => {
        if (mappingCompany) {
            return <React.Fragment>
                <CompanyPicker onSelectCompany={(companyName) => {
                    setMappedLocation(companyName);
                    const companyCoordinates = coordsInfo ? coordsInfo.addresses[0].position : '';
                    addMappedLocation(companyCoordinates, companyName);
                    setMappingCompany(false);
                }} />
            </React.Fragment>
        }

        return <React.Fragment>
            <Grid item sm={12} style={{ padding: '.5rem', width: '100%' }}>
                <Separator>Current location</Separator>
            </Grid>
            {locationInfo()}
            {coordinatesInfo()}
            <Grid item xs={12} style={gridStyle} container justifyContent='space-between'>
                <DefaultButton onClick={() => onNext('')}>Skip</DefaultButton>
                <PrimaryButton disabled={locatingInProgress} onClick={() => onNext(location ? `${location.latitude}, ${location.longitude}` : '', mappedLocation)}>Next</PrimaryButton>
            </Grid>
        </React.Fragment>
    }, [mappingCompany, locationInfo, coordinatesInfo, location, coordsInfo, locatingInProgress, mappedLocation]);

    return viewSelector();
}
