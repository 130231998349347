import { msalInstance } from '../index'
import { faRequest } from './Auth.config';

async function getHeaders() {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("An active account is required!");
    }

    // const response = await msalInstance.acquireTokenSilent({
    //     ...loginRequest,
    //     account: account
    // });

    const response = await msalInstance.acquireTokenSilent({
        ...faRequest,
        account: account
    });
    
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append('Authorization', bearer);
    headers.append('content-type', 'application/json');

    return headers;
}

export async function getGetOptions() {
    const headers = await getHeaders();
    const options = {
        method: 'GET',
        headers: headers
    };

    return options;
}

export async function getPostOptions(content: string){
    const headers = await getHeaders();
    const options = {
        method: 'POST',
        headers: headers,
        body: content
    };

    return options;
}