import { Text, Separator, PrimaryButton, TextField } from '@fluentui/react';
import { Grid } from '@material-ui/core';
import * as React from 'react';

interface IOdoInput {
    onNext: (odo: string) => void;
}

export const OdoInput: React.FC<IOdoInput> = ({ onNext }) => {
    const [odoReading, setOdoReading] = React.useState('');
    const onChangeOdoTextFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setOdoReading(newValue || '');
        },
        [],
    );

    return <React.Fragment>
        <Grid item sm={12} style={{ padding: '.5rem', width: '100%' }}>
            <Separator>Set odo information</Separator>
        </Grid>
        <Grid item xs={12} style={{ padding: '.5rem' }}>
            <Text variant='large' style={{ marginBottom: '1rem' }}>ODO:</Text>
        </Grid>
        <Grid item xs={12} style={{ padding: '.5rem' }}>
            <TextField
                value={odoReading}
                onChange={onChangeOdoTextFieldValue}
                inputMode={'numeric'}
            />
        </Grid>
        <Grid item xs={12} style={{ padding: '.5rem' }}>
            <PrimaryButton onClick={() => onNext(odoReading)}>Next</PrimaryButton>
        </Grid>
    </React.Fragment>
}
